import React, {useEffect, useState} from "react"
import { ThemeProvider } from "@material-ui/styles"
import { CssBaseline, NoSsr } from "@material-ui/core"
import { IntlProvider, addLocaleData } from "react-intl"
import { graphql } from "gatsby"
import { Location } from "@reach/router"

import {
  Header,
  Footer,
  ProductInformation,
  CartProvider,
  SEO,
  ProductBenefits,
  MobileOrientation,
} from "../../../components"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import Theme from "../theme"
import LeafLeft from "../images/leaf left.png"
import LeafRight from "../images/leaf right.png"
import locale_es from "react-intl/locale-data/es"
import locale_en from "react-intl/locale-data/en"
import moltinThreshold from "../../../constants/moltinThreshold"
import {client} from "../services/Moltin";

addLocaleData([...locale_en, ...locale_es])
const localeConsts = require("../../../constants/localeConsts")

const categoryColor = categorySlug => {
  switch (categorySlug) {
    case "beauty-line":
      return "black"
    case "moisture-and-protection":
      return "green"
    case "specialized-care":
      return "blue"
    default:
      return "green"
  }
}

export default ({
  pageContext: {
    product,
    images,
    esImages,
    footer,
    headerData,
    category,
    locale,
    slug,
  },
  location,
}) => {
  const pathPrefix = `/${locale + "/" + product.slug}`
  const newPath =
    locale === localeConsts.es
      ? `${pathPrefix + localeConsts.esPath}/`
      : `${pathPrefix + localeConsts.enPath}/`
  const imageData = images.map(image => ({
    src: image.link.href,
    alt: "",
  }))
  const esImageData = esImages.map(image => ({
    src: image.link.href,
    alt: "",
  }))
  const langKey = locale
  const i18nmessages = require(`../messages/index/${langKey}`)
  const outOfStockText = i18nmessages.outOfStock

  let productMap = new Map ()
  const [available, setAvailable] = useState(0)
  useEffect(() => {
    const amount = client
        .get(`inventories/${product.id}`)
        .then(result => productMap.set(result.data.id, result.data.available))
        .then(result => setAvailable(productMap.get(product.id)))
  },[])

  const isOutOfStock =
    // product.inventories.data.available <=
      available <=
    parseInt(moltinThreshold.thresholdValue)
  const keywords = ["Apple Stem Cells", "Organic CBD"]

  const langPrefix = langKey.substring(0, 2)
  const pageMetadata = {
    title: product[langPrefix + "MetadataTitle"],
    description: product[langPrefix + "MetadataDescription"],
    url: location.href,
  }

  return (
    <>
      <MobileOrientation />
      <SEO siteMetadata={pageMetadata} />
      <CssBaseline />
      <IntlProvider locale={langKey} messages={i18nmessages}>
        <ThemeProvider theme={Theme}>
          {/* <AuthWrapper> */}

          <CartProvider>
            <Location>
              {({ location }) => {
                if (newPath !== location.pathname) {
                  location.href = newPath
                }
              }}
            </Location>
            <Header
              data={
                langKey === localeConsts.es
                  ? headerData[1].node
                  : headerData[0].node
              }
              slug={slug}
            />
            <ProductInformation
              color={categoryColor(category.data.slug)}
              images={locale === localeConsts.en ? imageData : esImageData}
              name={product.name}
              price={product.meta.display_price.without_tax.formatted}
              description={product.description}
              cbdQuantity={product.cbd_quantity}
              keywords={keywords}
              itemId={product.id}
              volume={product.vol}
              product={product}
              outOfStock={outOfStockText}
              isOutOfStock={isOutOfStock}
              langKey={langKey}
              isComingSoon={product.isComingSoon}
            />
            <ProductBenefits
              locale={locale}
              productId={product.id}
              color={categoryColor(category.data.slug)}
            />
            <Footer
              data={
                langKey === localeConsts.es ? footer[1].node : footer[0].node
              }
              langKey={locale}
              bgImgLeft={LeafLeft}
              bgImgRight={LeafRight}
            />
          </CartProvider>
          {/* </AuthWrapper> */}
        </ThemeProvider>
      </IntlProvider>
    </>
  )
}
